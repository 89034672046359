<template>
  <b-container>
    <b-row class="justify-content-md-center mt-5">
      <b-col>
        <b-icon style="width: 30px; height: 30px;" class="text-success mb-2" icon="check-circle"></b-icon>
        <h5>We've just sent you an email to complete the signup process</h5>
        <p>Please note the email may take a few minutes to arrive. If you want us to resend another verification email, <a href="#" @click="resendVerificationEmail()">click here</a> .</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

export default {
  name: "SignupEmail",
  data() {
    return {};
  },
  methods: {
    emailSentToaster() {
      this.toastCount++;
      this.$bvToast.toast("We've just sent you an email to complete the signup process", {
        variant: "success",
        title: "Email Verification",
        autoHideDelay: 10000,
        appendToast: false,
      });
    },
    resendVerificationEmail() {
      let user = firebase.auth().currentUser;
      var actionCodeSettings = {url: "https://research.mindthebridge.com/login?email=" + user.email}
      user.sendEmailVerification(actionCodeSettings).then(() => { 
        // console.log("EMAIL SENT");
        this.emailSentToaster();
      })
    },
  },
};
</script>
